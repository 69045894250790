// author:马云阳；description:众惠
<template>
  <section class="NavRightSide">
    <div class="nav_cont">
      <div class="nav_titles">
        <span class="titles">我的众惠</span>
        <img
          src="@/static/img/trade_flow/trade-huijinbao-select.png"
          alt=""
        >
      </div>
      <div class="nav_name">
        <div class="name_">
          <img
            :src="userinfos.avatar"
            alt=""
            class="head_img"
          >
          <div class="right_name_phone">
            <div
              style="text-align:left; margin-bottom:12px"
              class="name onlnes"
            >
              {{name||''}}
            </div>
            <div class="phone_">
              {{(mobile||'').$hide()}}
            </div>
          </div>
        </div>
        <div class="tag_title">
          <img
            src="@/static/img/my/power_.png"
            alt=""
          >
          <span class="ti_center">风险承受能力</span>
          <span class="ti_right">{{hs_risk_Leveldata[userinfos.riskLevel]||'未评测'}}</span>
        </div>
      </div>
      <!-- nav -->
      <div
        v-for="(obj,i) in navList"
        :key="i"
        class="list_"
      >
        <a
          :class="listId==obj.name&&obj.type!=1?'nav_cont_listAC':''"
          class="myAssets nav_list"
          @click="showNavlist(obj)"
        >
          <div
            :class="showright&&ids==obj.name?'nav_list_ac':''"
            class="nav_txt"
          >
            {{ obj.navtitle }}
          </div>
          <!-- <img v-if="obj.type==1" :src="showright&&ids==obj.id?'" alt=""> -->
          <div
            v-if="obj.type==1"
            :class="showright&&ids==obj.name?'imgs_ac':''"
            class="imgs_"
          >
            <span
              class="iconfont"
              style="font-size:10px;"
            >&#xe679;</span>
          </div>
        </a>
        <div
          v-show="showindex==obj.name&&showright"
          class="list_Children"
        >
          <a
            v-for="(conts,j) in obj.navChildren"
            :key="j"
            :class="listId==conts.name?'nav_cont_listAC':''"
            class="nav_cont_list"
            @click.stop="toPagebool==conts.name?'':toPage(conts)"
          >
            {{ conts.titles }}
          </a>
        </div>
      </div>
      <div v-if="imgs_banner" class="bannaer_">
        <img :src="imgs_banner" alt="">
      </div>
    </div>
  </section>
</template>
<script>
// import RightBar from "./RightBar.vue";
import { mapGetters } from "vuex";
import { clearTimeout } from "timers";
import { getImgPage } from "@/api/activeList";

export default {
  name: "NavRightSide",
  components: {},
  props: {
    userinfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      showright: false, //默认收起导航
      showindex: "", //导航一级索引
      ids: null, //导航一级索引ID
      listId: null, //导航二级ID
      imgs_banner:null,
      navList: [
        {
          navtitle: "我的资产",
          type: 1,
          id: 1,
          name: "MyFundIndex",
          navChildren: [
            { titles: "我的资产首页", id: 1001, name: "MyFundIndex" },
            { titles: "惠金宝", id: 2, name: "Hui" },
            { titles: "公募基金", id: 3, name: "PublicFund" },
            { titles: "高端理财", id: 4, name: "PrivateFund" }
            // { titles: "资管计划", id: 5, name: "AssetMange" }
          ]
        },
        {
          navtitle: "交易记录",
          type: 2,
          id: 6,
          name: "TransactionRecord",
          navChildren: []
        },
        {
          navtitle: "基金定投",
          type: 2,
          id: 7,
          name: "FundSetFing",
          navChildren: []
        },
        {
          navtitle: "账户管理",
          type: 1,
          id: 8,
          name: "MyAccount",
          navChildren: [
            { titles: "我的账户", id: 28, name: "MyAccount" },
            { titles: "银行卡管理", id: 9, name: "BankCardManagement" },
            { titles: "个人信息", id: 34, name: "PersonalInfo" },
            { titles: "安全中心", id: 11, name: "SafetyCentre" },
            { titles: "分红设置", id: 12, name: "DividentFit" },
            { titles: "风险测评", id: 13, name: "RiskAssessment" },
            { titles: "资产收入证明", id: 14, name: "AssetProve" }
          ]
        },
        {
          navtitle: "邀请好友",
          type: 2,
          id: 15,
          name: "invitation",
          navChildren: []
        },
        {
          navtitle: "相关协议",
          type: 2,
          id: 16,
          name: "agreement",
          navChildren: []
        }
      ],
      userinfos: {}, //用户信息
      mobelies: null,
      hs_risk_Leveldata: {}, //风险评级
      toPagebool: "" //当前页面路由
    };
  },
  computed: {
    ...mapGetters(["name", "mobile"])
  },
  watch: {
    $route(to) {
      this.toPagebool = to.name;
      let tim = setTimeout(() => {
        if (to.meta.chdr && to.meta.name && to.meta.type == 1) {
          this.ids = null;
          this.listId = to.meta.name;
          this.showright = false;
          this.showindex = "";
        } else if (to.meta.name && to.meta.type == 1) {
          this.ids = null;
          this.listId = to.meta.name;
          this.showright = false;
          this.showindex = "";
        } else if (
          to.meta.name &&
          to.meta.type == 2 &&
          to.meta.chdr &&
          to.meta.titles
        ) {
          this.showright = true;
          this.ids = to.meta.titles;
          this.listId = to.meta.name;
          this.showindex = to.meta.titles;
        } else if (to.meta.name && to.meta.type == 2 && to.meta.titles) {
          this.showright = true;
          this.ids = to.meta.titles;
          this.listId = to.meta.name;
          this.showindex = to.meta.titles;
        }
        tim = null;
        clearTimeout(tim);
      }, 10);
    }
  },
  methods: {
    showNavlist(o) {
      if (o.type == 1) {
        // if (this.showright) {
        //   this.showright = false;
        //   return;
        // } else {
        // }
        this.showright = true;
        this.showindex = o.name;
        this.ids = o.name;
        if (o.name == this.toPagebool) {
          return;
        } else {
          this.$router.push({
            name: o.name
          });
        }
      } else {
        this.ids = null;
        this.listId = o.name;
        if (o.name == this.toPagebool) {
          return;
        } else {
          this.$router.push({
            name: o.name
          });
        }
      }
    },
    getImgPagedata() {
      getImgPage({
        pageNum: 1,
        pageSize: 10,
        imgUsePlatform: "1",
        imgUseSite: "9"
      }).then(res=>{
        this.imgs_banner=res.data.data&&res.data.data[0]&&res.data.data[0].atchPath||null
      })
    },
    toPage(e) {
      this.listId = e.name;
      console.log('---执行否')
      if(e.name=='PrivateFund'){
        if(!this.isTradableNew()){
          return
        }
      }
      if (e.name) {
        this.$router.push({ name: e.name });
      } else if (e.path) {
        this.$router.push(e.path);
      }
    },
    getindex() {
    },
    getDictsdatas(val, option) {
      this.getDicts(val).then(res => {
        res.data.forEach(item => {
          // this[option][item.dictValue] = item.dictLabel
          this.$set(this[option], item.dictValue, item.dictLabel);
        });
      });
    }
  },
  created() {
    this.userinfos =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    this.getDictsdatas("hs_risk_Level", "hs_risk_Leveldata");
    this.getImgPagedata();
    // let str = this.userinfos.mobileTel.slice(0, 3);
    // let str2 = this.userinfos.mobileTel.slice(-4);
    // this.mobelies = str + "****" + str2;
  }
};
</script>
<style scoped lang="less">
.onlnes {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NavRightSide {
  width: 230px;
  background: #fff;
  margin-right: 40px;
}
.NavRightSide .nav_cont {
}
.bannaer_{
 padding: 30px 13px;
  img{
    width: 204px;
    height: 204px;
  }
}
.NavRightSide .nav_cont .nav_titles {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
}
.NavRightSide .nav_cont .nav_titles .titles {
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 300;
}
.NavRightSide .nav_cont .nav_titles img {
  width: 28px;
  height: 28px;
}
.NavRightSide .nav_cont .nav_name {
  height: 118px;
  /* padding: 20px 0px; */
  padding: 20px 24px 0px 24px;
}
.NavRightSide .nav_cont .nav_name .name_ {
  display: flex;
  align-items: center;
}
.NavRightSide .nav_cont .nav_name .name_ .head_img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 5px solid #f6f5f3;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin-right: 10px;
}
.NavRightSide .nav_cont .nav_name .name_ right_name_phone {
  display: flex;
  flex-direction: column;
  color: #1f1f1f;
  font-size: 14px;
}

.NavRightSide .nav_cont .nav_name .tag_title {
  margin-top: 20px;
  width: 150px;
  height: 28px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  background: linear-gradient(98deg, #4e494d 0%, #0b0409 100%);
  border-radius: 14px;
  box-shadow: 0px 4px 12px 0px #edf1f7;
}
.NavRightSide .nav_cont .nav_name .tag_title img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.NavRightSide .nav_cont .nav_name .tag_title .ti_center {
  font: 12px/1em "microsoft yahei";
  color: #fff;
  margin-right: 1em;
}
.NavRightSide .nav_cont .nav_name .tag_title .ti_right {
  color: #ce9b63;
  font: 12px/1em "microsoft yahei";
  font-weight: bold;
}
.NavRightSide .nav_cont .list_ .nav_list {
  display: inline-block;
  width: 134px;
  height: 70px;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_list_ac {
  color: #ce9b63 !important;
}
.NavRightSide .nav_cont .list_ .nav_list .nav_txt {
  font-size: 16px;
  font-weight: 400;
}
.imgs_ac {
  transform: rotate(90deg);
  color: #e8ba8c;
}
.NavRightSide .nav_cont .list_ .list_Children .nav_cont_list {
  height: 70px;
  text-align: center;
  font-size: 16px;
  color: #a5a5a5;
  line-height: 70px;
  display: inline-block;
  width: 229px;
}
.nav_cont_listAC {
  background: #fffaf4;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  color: #ce9b63 !important;
  border-left: 1px solid #ce9b63;
}
</style>

