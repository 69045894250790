<template>
  <div class="bread-crumb">
    <span>当前位置：</span>
    <template v-for="(item, index) in links">
      <router-link :to="item.path" :key="item.name" exact-active-class="active">{{ item.title }}</router-link>
      <span :key="item.name + index" v-if="index !== links.length - 1" class="cut"> > </span>
    </template>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        links: [],
      }
    },
    watch: {
      $route:{
        handler({ matched }) {
          console.log(matched)
          this.links = matched.map(item => {
            return {
              name: item.name,
              path: item.path,
              title: item.meta.title
            }
          })
        },
        deep: true,
        immediate: true
      }
    },
  }
</script>

<style lang="less" scoped>
.bread-crumb {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 14rpx;
  color: #A5A5A5;
  .router-link {
    font-size: 14rpx;
    color: #A5A5A5;
  }
  .router-link-active {
    color: #A5A5A5;
  }
  .active {
    color: #6B6B6B;
  }
  .cut {
    margin: 0 4px;
  }
}
</style>