<template>
  <div class="my-favors">
    <div class="favors-contain">
      <nav-right-side class="nav" />
      <div class="favors-content">
        <breadCrumb />
        <router-view />
      </div>
    </div>   
  </div>
</template>

<script>
  import NavRightSide from '@/components/NavRightSide'
  import breadCrumb from '@/components/breadCrumb'
  export default {
    components: {
      NavRightSide,
      breadCrumb
    }
  }
</script>

<style lang="less" scoped>
  .my-favors {
    width: 100%;
    background: #F3F4F6;
    .favors-contain {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      justify-content: space-between;
    }
    .nav {
      margin-top: 64px;
    }
    .favors-content {
      width: 930px;
    }
  }
</style>